<template>
  <window-default ref="window" :resolve="find" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 100px">
          <erp-s-field
              label="UF:"
          >
            <erp-input v-model="model.uf" autofocus size="2"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              label="Nome / Pessoa relacionada:"
          >
            <global-pessoa-input v-model="model.pessoa" />
          </erp-s-field>
        </e-col>
      </e-row>

<!--      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome:"
          >
            <erp-input v-model="model.nome" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>-->

      <e-row mr>

      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Matrícula:"
          >
            <erp-input v-model="model.matricula" size="2"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              label="Saudação / Título:"
          >
            <erp-input v-model="model.saudacao" size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              label="RG:"
          >
            <erp-input v-model="model.rg" size="2"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              label="CPF:"
          >
            <erp-input v-model="model.documento" size="2"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              label="Inscrição Mun./E:"
          >
            <erp-input v-model="model.ie" size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              label="URL Logomarca:"
          >
            <erp-input v-model="model.urlLogomarca" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="URL do Site:"
          >
            <erp-input v-model="model.urlSite" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Endereço:"
          >
            <erp-input v-model="model.endereco" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Telefone(s):"
          >
            <erp-input v-model="model.telefones" autofocus size="2"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              label="E-mail(s):"
          >
            <erp-input v-model="model.emails" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Numeração atual da Nota de Arrematação:"
          >
            <erp-input v-model="model.numeracaoNota" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active"/>
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import {
  findLeiloeiro as find,
  newLeiloeiro as _new,
  updateLeiloeiro as update,
} from '../../../../../domain/cadastro/services'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import save from '../../../helpers/save'
import {convertRealToMoney} from "@/utils/money";
import {datePtToEn} from "@/utils/date";
import GlobalPessoaInput from "components/pessoa/components/include/pessoa/PessoaInput"

let mock = {
  active: null,
  nome: null,
  uf: null,
  urlLogomarca: null,
  urlSite: null,
  saudacao: 'Leiloeiro(a) Oficial',
  matricula: null,
  rg: null,
  documento: null,
  ie: null,
  endereco: null,
  telefones: null,
  emails: null,
  numeracaoNota: null,
  extra: null
}

export default {
  name: 'Window',
  components: {GlobalPessoaInput, ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox},
  props: ['props'],
  data() {
    return {
      model: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted() {
  },
  computed: {
    find() {
      return find
    },
    mock() {
      return mock
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    beforeSave (originalData) {
      let data = JSON.parse(JSON.stringify(originalData))
      if (data.pessoa && data.pessoa.id) {
        data.nome = data.pessoa.name
        data.pessoa = data.pessoa.id
      }
      return data
    }
  }
}
</script>
